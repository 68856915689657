import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      {/* Main Footer Section */}
      <div className="footer-content">
        <p className="footer-rights">@2024 All Rights Reserved</p>
        <ul className="footer-links">
          <li><a href="/privacy">Privacy</a></li>
          <li><a href="/terms">Terms</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>

      {/* Creator Section with Dark Purple Background */}
      <div className="footer-creator">
        <p>Created by <a href="https://raineecorn.me" target="_blank" rel="noopener noreferrer">raineecorn.me</a></p>
      </div>
    </footer>
  );
};

export default Footer;
