import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const OptionCards = ({ onClose }) => {
  return (
    <div className="modal fade show d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 1050 }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 shadow-lg">
          <div className="modal-header border-0">
            <h5 className="modal-title mx-auto">Track Your Parcel</h5>
          </div>
          <div className="modal-body text-center">
            <p className="text-muted">Enter details to track your package.</p>
            <Link className="btn btn-primary btn-lg w-100 py-2" to="/tracking" onClick={onClose}>
              📦 Track a Parcel
            </Link>
          </div>
          <div className="modal-footer border-0">
            <button type="button" className="btn btn-secondary w-100" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionCards;
