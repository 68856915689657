import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import ordered from '../../images/statusTrack/ordered.png';
import inTransit from '../../images/statusTrack/in_transit.png';
import delivered from '../../images/statusTrack/delivered.png';
import arrived from '../../images/statusTrack/arrivedAt.png';
import notFound from '../../images/statusTrack/notFound.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = 'https://crqpfmcvudkvwsvyenkv.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNycXBmbWN2dWRrdndzdnllbmt2Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyODg1OTg5NiwiZXhwIjoyMDQ0NDM1ODk2fQ.HQxNGutNq1P9b9eXZxLYHw4LGXjvNe2XI60gMAki4Es';
const supabase = createClient(supabaseUrl, supabaseAnonKey);


const TrackingStatus = ({ trackingNumber }) => {
  const [trackingStatus, setTrackingStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrackingStatus = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('trackingfbt_audit') 
          .select('audit_id, tracking_id, date_loaded, remarks')
          .eq('tracking_id', trackingNumber); 

        if (error) {
          throw new Error(error.message);
        }

        const statuses = data.map(item => ({
          ...item,
          image: getImageForStatus(item.remarks)
        }));

        setTrackingStatus(statuses);
      } catch (error) {
        console.error('Error fetching tracking status:', error);
        setTrackingStatus([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTrackingStatus();
  }, [trackingNumber]);

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleDateString(); 
  };

  return (
    <div className="container mt-4 p-3 bg-white rounded shadow-sm">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center w-100 flex-wrap">
          {trackingStatus.length > 0 ? (
            trackingStatus.map((status, index) => (
              <div 
                key={index} 
                className="d-flex flex-column align-items-center text-center"
                style={{
                  flex: `1 1 ${100 / trackingStatus.length}%`,  
                  minWidth: '100px',  
                }}
              >
                {/* Status Image */}
                <img
                  src={status.image}
                  alt={status.remarks}
                  className="img-fluid"
                  style={{ width: '50px', height: '50px' }} 
                />
                {/* Status Text */}
                <p className="mt-2 text-dark fw-bold" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
                  {status.remarks}
                </p>
                <span className="text-muted" style={{ fontSize: '10px' }}>
                  {formatTimestamp(status.date_loaded)}
                </span>
              </div>
            ))
          ) : (
            <p className="text-center w-100">No tracking information available.</p>
          )}
        </div>
      )}
    </div>
  );
};

// Map status remarks to images
const getImageForStatus = (remarks) => {
  const imageMap = {
    'Bangkok Warehouse': ordered,
    'Loaded': inTransit,
    'Loading': inTransit,
    'Sailing': inTransit,
    'Clearance': inTransit,
    'delivered': delivered,
    'arrived': arrived,
    'Manila Warehouse': arrived, 
    'out for delivery': delivered,
    'not found': notFound,
    'no tracking': notFound,
  };

  for (let key in imageMap) {
    if (remarks.toLowerCase().includes(key.toLowerCase())) {
      return imageMap[key];
    }
  }
  return notFound;
};

// Define PropTypes for validation
TrackingStatus.propTypes = {
  trackingNumber: PropTypes.string.isRequired,  
};

export default TrackingStatus;
